$(function() {

    svg4everybody();

    // js-gallery-img

    $('.js-gallery-img').magnificPopup({
      type: 'image',
      gallery:{
        enabled:true,

        arrowMarkup: `<button title="%title%" type="button" class="mfp-arrow mfp-arrow-%dir%"></button>`, // markup of an arrow button

        tPrev: 'Предыдущий', // title for left button
        tNext: 'Следующий', // title for right button
        tCounter: '<span class="mfp-counter">%curr% из %total%</span>' // markup of counter
      }
    });


    //form
    $('.js-form').on('submit', function(event) {
       event.preventDefault();

       const $form = $(this);
       const $message = $(this).find('.js-form-message');

       $.ajax({
           type: /*'get' ||*/ $form.attr('method'),
           url: /*'#' || */$form.attr('action'),
           data: $form.serialize()
       }).done(function() {
           console.log('success');
           $message.addClass('is-active');
       }).fail(function() {
           console.log('fail');
           alert('Ошибка отправки')
       });

    });

   
    // top sliders
    $(".js-top-slider").each(function(index, el) {
        const $parent = $(el).closest('.js-top-slider-wrapper');
        const $textList = $parent.find('.js-top-slider-text');

        $(el).on('init', function(){
          $textList.eq(0).show();
        });

        $(el).slick({
            infinite: true,
            // autoplay: true,
            speed: 1000,
            dots: false,
            fade: true,
            prevArrow: "<button class='top-slider__arrow top-slider__arrow--prev'><svg class='icon'><use xlink:href='#caret'></use></svg></button>",
            nextArrow: "<button class='top-slider__arrow top-slider__arrow--next'><svg class='icon'><use xlink:href='#caret'></use></svg></button>",

        });



        $(el).on('beforeChange', function(event, slick, currentSlide, nextSlide){
          $textList.hide();
          $textList.eq(nextSlide).show();
        });  
    });

      

    // previews sliders
    $(".js-previews").each(function(index, el) {
        const $parent = $(el).closest('.previews');

        $(el).slick({
            responsive: [
                    {
                        breakpoint: 9999,
                        settings: {
                            infinite: true,
                            autoplay: true,
                            autoplaySpeed: 0,
                            rtl: el.dir === 'rtl' ? true : false,
                            speed: 5000,
                            dots: false,
                            arrows: false,
                            variableWidth: true,
                            cssEase: 'linear',
                        }
                    },
                    {
                        breakpoint: 1200,
                         settings: 'unslick'
                    }
                ]

        });

        $(el).slick('pause');


        $parent.mouseover(function() {
            $(el).slick('play') 
        });

        $parent.mouseleave(function() {
            $(el).slick('pause')
        });
    });

    // for previews slider - reinit on tablet when window resize
    window.matchMedia('(max-width: 1200px)').addEventListener('change', (e) => {
        if (!e.matches) {
            $(".js-previews").slick('reinit');
        } 
    });

    // menu appear
    $(document).on('click', '.js-menu-toggler', function(event) {
        $('.js-menu-toggler').toggleClass('is-active');
        $(this).closest('.js-menu-wrapper').toggleClass('is-active');
        $('body').toggleClass('is-menu-active');
    });

    // file-input customization
    $(document).on('change', '.js-attach-input', function(event) {
        const name = (this.files && this.files.length ) ? this.files[0].name || '' : '';   
        const $parent = $(this).closest('.js-attach');
        $parent.find('.js-attach-name').text(name);

    });

    // tabs
    $(document).on('click', '.js-tabs-btn', function(event) {
        event.preventDefault();
        const $parent = $(this).closest('.js-tabs');
        const id = $(this).data('tab');
        const $contents = $parent.find('.js-tabs-content');


        $contents.each(function(index, el) {

            if($(el).data('tab') === id ) {
              $(el).addClass('is-active');
              
              const $slider = $(el).find('.js-top-slider');

              if ($slider.length) {
                // topSliderInit($slider[0]);
                $slider.slick('reinit');
              }

            } else {
                $(el).removeClass('is-active')
            }
        });
        $(this).addClass('is-active').siblings('.js-tabs-btn').removeClass('is-active');
    });

// fixed header
    let lastScrollTop = 0;
    $(window).scroll(function () {
    
        let st = window.pageYOffset || document.documentElement.scrollTop; // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
        if (st > lastScrollTop){
            $("header").removeClass("is-appear");
        } else {
            $("header").addClass("is-appear");
        }
        lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling


        if($(window).scrollTop() >= 1) {
            $("header").addClass("is-fixed");
        } else {
            $("header").removeClass("is-fixed");
        }
    });

    // scroll to anchor
       $('.js-anchor-link').on('click', function(e) { 
           e.preventDefault();
           let selector = $(this).attr('href');

           if (!$(selector).length) return;

           let h = $(selector).offset().top;
           $('html, body').animate({scrollTop: h - 70}, 400)
       })

});

